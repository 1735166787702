<template>
  <v-dialog v-model="dialog" max-width="500px">
    <template v-slot:activator="{ on }">
      <v-list-item v-on="on">
        <v-list-item-action>
          <v-icon>mdi-upload</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Upload Remessa</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-card>
      <v-card-title>
        Upload do Arquivo de Remessa
      </v-card-title>
      <v-card-text>
        Aqui você pode fazer o upload do arquivo de remessa referente a pagamentos de boletos.
        <v-file-input label="Remessa" v-model="file_remessa" />
        <v-alert v-if="errorMsg" dense outlined type="error">Arquivo inválido.</v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="upload">Fazer upload</v-btn>
        <v-btn text @click="dialog = false; errorMsg = false; file_remessa = null;">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from '../services/axios'

export default {
  data () {
    return {
      dialog: false,
      file_remessa: null,
      errorMsg: false
    }
  },

  methods: {
    async upload () {
      const formData = new FormData()
      formData.append('file_remessa', this.file_remessa)

      try {
        const response = await axios.post('/api/boletos/remessa/', formData, {
          headers: { ...axios.options.headers, 'Content-Type': 'multipart/form-data' }
        })
        this.errorMsg = false
        this.dialog = false
        this.file_remessa = null
        const { id } = response.data
        this.$router.push(`/transfers/${id}`)
      } catch (error) {
        if (error.response.status === 400) {
          this.errorMsg = true
        }
      }
    }
  }
}
</script>
