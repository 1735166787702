<template>
  <v-menu v-model="menu" offset-y :close-on-content-click="false">
    <template v-slot:activator="{ on }">
      <v-btn text v-on="on">
        {{ account.username }}
      </v-btn>
    </template>
    <v-card>
      <v-list>
        <v-list-item @click="logout">
          <v-list-item-action>
            <v-icon>mdi-logout-variant</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            Sair
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      menu: false
    }
  },

  computed: mapState(['account']),

  methods: {
    logout () {
      this.$store.dispatch('account/logout')
    }
  }
}
</script>
