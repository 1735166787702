import axios from '../../services/axios'

const ITEMS_PER_PAGE = 7

const state = {
  data: [],
  nextPage: null,
  previousPage: null,
  pages: 0,
  selectedRequest: null,
  selectedResponse: null
}

const getters = {
  getRequestedBoletoById (state) {
    return (id) => state.data.find(boleto => boleto.id === id)
  }
}

const mutations = {
  clearRequestedBoletos (state) {
    state.data = []
  },

  setRequestedBoletos (state, { payload }) {
    state.data = payload.results
    state.nextPage = payload.next
    state.previousPage = payload.previous
    state.pages = Math.ceil(payload.count / ITEMS_PER_PAGE)
  },

  setSelectedBoleto (state, { payload, id }) {
    state.selectedRequest = state.data.find(boleto => boleto.id === id)
    state.selectedResponse = payload
  }
}

const actions = {
  async getRequestedBoletos ({ commit }, props) {
    let paramsTxt = '?'
    for (const x in props?.params) {
      if (!props?.params[x]) continue
      if (x === 'status' && props?.params[x] !== 'todos') { paramsTxt += `${x}=${props?.params[x]}&` }
    }

    const url = props?.url || `/api/boletos/request/${paramsTxt}`

    const response = await axios.get(url)

    await commit('setRequestedBoletos', { payload: response.data })
  },

  async getRespostaBoleto ({ commit }, id) {
    const url = `/api/boletos/response/${id}`

    try {
      const response = await axios.get(url)

      await commit('setSelectedBoleto', { payload: response.data, id })
    } catch (e) {
      // If 404, clear the current infos
      await commit('setSelectedBoleto', { payload: null, id })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
