<template>
  <v-main>
    <v-card>
      <v-card-title>Arquivos de Retorno / Transferências</v-card-title>
      <v-container fluid>
        <v-row>
          <v-col md-3>
            Selecione o ano:
            <v-select
              v-model="selectedYear"
              :items="selectYears"
              @change="year => fetchRemessas({ year, month: selectedMonth })"
              label="Selecione"
              solo
            ></v-select>
          </v-col>
          <v-col md-3>
            Selecione o mês:
            <v-select
              v-model="selectedMonth"
              :items="selectMonths"
              @change="month => fetchRemessas({ year: selectedYear, month })"
              item-text="text"
              item-value="value"
              label="Selecione"
              solo
            ></v-select>
          </v-col>
          <v-col md-6>
            Selecione o arquivo:
            <v-select
              :items="remessa.all ? remessa.all : []"
              item-text="file_name"
              item-value="id"
              label="Selecione"
              @change="fetchRemessa"
              solo
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
      <v-divider />
      <v-container>
        <v-card-title>
          Saldo disponível:
          <v-progress-circular v-if="loadingBalance" indeterminate color="primary" />
          <span class="ml-2" v-else> {{ formatCurrency(banking.balance) }}</span>
        </v-card-title>
        <v-card-subtitle>
          Saldo disponível em conta via TED do Bradesco
        </v-card-subtitle>
        <v-card-title>
          Saldo necessário:
          <span class="ml-2" v-if="!!remessa.selected">
            {{ formatCurrency(parseFloat(remessa.selected.required_amount)) }}
          </span>
        </v-card-title>
        <v-card-subtitle>
          Saldo necessário para liquidar todos boletos do arquivo de retorno
        </v-card-subtitle>
        <v-card-title>
          Arquivo de retorno selecionado: {{remessa.selected && remessa.selected.file_name}}
        </v-card-title>
        <v-card-subtitle>
          Status: {{remessa.selected && remessa.selected.status}}
        </v-card-subtitle>
        <v-card-actions>
          <v-btn color="primary" :disabled="disableLiquidarBtn" @click="startPayment">
          <v-icon left>mdi-cash-refund</v-icon>
            {{remessa.selected && mapRemessaBtnStatusText[remessa.selected.status.toLowerCase()]}}
          </v-btn>
        </v-card-actions>
        <v-list v-if="!!remessa.selected">
          <v-list-item v-for="(b, i) in remessa.selected.boletos" :key="i" @click="() => {}">
            <v-list-item-title>{{b.nomePagador}}</v-list-item-title>
            <v-list-item-subtitle>id: {{b.id}}</v-list-item-subtitle>
            <v-list-item-subtitle>Valor pago: {{formatCurrency(b.valor_pago)}}</v-list-item-subtitle>
            <v-list-item-subtitle>Emissão: {{b.dtEmissaoTitulo}}</v-list-item-subtitle>
            <v-list-item-subtitle>Vecimento: {{b.dtVencimentoTitulo}}</v-list-item-subtitle>
            <v-list-item-subtitle>Status: {{b.status}}</v-list-item-subtitle>
          </v-list-item>
        </v-list>
      </v-container>
    </v-card>
  </v-main>
</template>

<script>
import { mapState } from 'vuex'
import axios from '../services/axios'

const currencyFormatter = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })

export default {
  created () {
    // Return list of remessas of current year/month
    const today = new Date()
    const month = today.getMonth() + 1
    const year = today.getFullYear()
    this.fetchRemessas({ year, month })

    // Set years for
    const yearFrom = 2019
    for (let i = year; i >= yearFrom; i--) { this.selectYears.push(i) }
    this.selectedYear = year
    this.selectedMonth = month

    const { id } = this.$route.params
    this.fetchBalance()
    if (id) { this.fetchRemessa(id) }
  },

  data () {
    return {
      selectedYear: 2021,
      selectedMonth: 9,
      selectYears: [],
      selectMonths: [
        {
          value: 1,
          text: 'Janeiro'
        },
        {
          value: 2,
          text: 'Fevereiro'
        },
        {
          value: 3,
          text: 'Março'
        },
        {
          value: 4,
          text: 'Abril'
        },
        {
          value: 5,
          text: 'Maio'
        },
        {
          value: 6,
          text: 'Junho'
        },
        {
          value: 7,
          text: 'Julho'
        },
        {
          value: 8,
          text: 'Agosto'
        },
        {
          value: 9,
          text: 'Setembro'
        },
        {
          value: 10,
          text: 'Outubro'
        },
        {
          value: 11,
          text: 'Novembro'
        },
        {
          value: 12,
          text: 'Dezembro'
        }
      ],
      loadingBalance: true,
      mapRemessaBtnStatusText: {
        recebido: 'Liquidar',
        processando: 'Processando transferências...',
        falha: 'Falha ao liquidar, tentar novamente?',
        liquidado: 'Remessa liquidada!'
      }
    }
  },

  computed: {
    ...mapState(['banking', 'remessa']),

    disableLiquidarBtn () {
      if (!this.remessa.selected) { return true }
      return (this.remessa.selected.status === 'LIQUIDADO') ||
             (this.remessa.selected.status === 'PROCESSANDO') ||
             (!this.banking.balance) || (this.loadingBalance) ||
             ((this.banking.balance && this.remessa.selected) &&
                this.banking.balance - this.remessa.selected.required_amount < -0.001)
    }
  },

  methods: {
    async fetchBalance () {
      await this.$store.dispatch('banking/getBalance')
      this.loadingBalance = false
    },

    async fetchRemessas ({ year, month }) {
      await this.$store.dispatch('remessa/getRemessas', { year, month })
    },

    async fetchRemessa (id) {
      await this.$store.dispatch('remessa/getRemessa', { id })
    },

    async startPayment () {
      this.remessa.selected.status = 'PROCESSANDO'
      await axios.post(`/api/boletos/remessa/${this.remessa.selected.id}/process/`)
    },

    formatCurrency (amount) {
      return currencyFormatter.format(amount)
    }
  }
}
</script>
