<template>
  <v-card>
    <v-card-title class="justify-center">Todos boletos</v-card-title>
    <v-overlay v-if="loading" aboslute class="text-center">
      <v-progress-circular indeterminate />
    </v-overlay>
    <v-list>
      <div v-for="(b, i) in requestedBoletos.data" :key="i">
        <RequestedBoletosListItem :boleto="b" />
        <v-divider v-if="i < requestedBoletos.data.length - 1" />
      </div>
    </v-list>
    <v-card-actions>
      <v-btn text
        :disabled="!requestedBoletos.previousPage"
        @click="handleChangePage(requestedBoletos.previousPage, -1)"
      >
        <v-icon>mdi-chevron-left</v-icon> Anterior
      </v-btn>
      <v-spacer />
      {{currentPage}}/{{ requestedBoletos.pages }}
      <v-spacer />
      <v-btn text
        :disabled="!requestedBoletos.nextPage"
        @click="handleChangePage(requestedBoletos.nextPage, 1)"
      >
        Próximo <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'

import RequestedBoletosListItem from './RequestedBoletosListItem.vue'

export default {
  components: {
    RequestedBoletosListItem
  },

  created () {
    this.fetchData({
      params: this.$route.params
    })
  },

  data () {
    return {
      loading: false,
      currentPage: 1
    }
  },

  computed: {
    ...mapState(['requestedBoletos'])
  },

  methods: {
    async fetchData (props) {
      this.loading = true
      await this.$store.dispatch('requestedBoletos/getRequestedBoletos', props)
      this.loading = false
    },

    async handleChangePage (url, direction) {
      await this.fetchData({ url })
      this.currentPage += direction
    }
  }
}
</script>
