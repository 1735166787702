import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Transfers from '../views/Transfers.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home/:status?',
    name: 'Home',
    component: Home,
    props: true
  },
  {
    path: '/transfers/:id?',
    name: 'Transfers',
    component: Transfers,
    props: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
