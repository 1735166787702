import axios from 'axios'
import router from '../router'
import store from '../store'

const request = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}`
})

request.interceptors.request.use(config => {
  const token = window.localStorage.getItem('token')

  if (token) {
    config.headers.common.Authorization = `Bearer ${token}`
  }

  return config
})

request.interceptors.response.use((response) => {
  return response
}, (error) => {
  if (error.response.status === 401) {
    store.dispatch('account/logout')
    router.push({ name: 'Home' })
  }

  throw error
})

export default request
