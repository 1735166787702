<template>
  <div>
    <v-navigation-drawer v-model="drawer" clipped fixed app>
      <v-list>
        <template v-if="$store.getters['account/loggedin']">
          <v-list-item to='/home/todos'>
            <v-list-item-action>
              <v-icon>mdi-bank-transfer-in</v-icon>
            </v-list-item-action>
            <v-list-item-content>Todos</v-list-item-content>
          </v-list-item>
          <v-list-item to='/home/solicitado'>
            <v-list-item-action>
              <v-icon>mdi-clock-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>Solicitados</v-list-item-content>
          </v-list-item>
          <v-list-item to='/home/registrado'>
            <v-list-item-action>
              <v-icon>mdi-content-save</v-icon>
            </v-list-item-action>
            <v-list-item-content>Registrados</v-list-item-content>
          </v-list-item>
          <v-list-item to='/home/recebido'>
            <v-list-item-action>
              <v-icon>mdi-elevator-down</v-icon>
            </v-list-item-action>
            <v-list-item-content>Recebidos</v-list-item-content>
          </v-list-item>
          <v-list-item to='/home/transferido'>
            <v-list-item-action>
              <v-icon>mdi-account-cash-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>Transferidos</v-list-item-content>
          </v-list-item>
          <v-list-item to='/home/vencido'>
            <v-list-item-action>
              <v-icon>mdi-clock-alert</v-icon>
            </v-list-item-action>
            <v-list-item-content>Vencidos</v-list-item-content>
          </v-list-item>
          <v-list-item to='/home/falha'>
            <v-list-item-action>
              <v-icon>mdi-cancel</v-icon>
            </v-list-item-action>
            <v-list-item-content>Falha</v-list-item-content>
          </v-list-item>
          <v-list-item to='/home/expirado'>
            <v-list-item-action>
              <v-icon>mdi-cancel</v-icon>
            </v-list-item-action>
            <v-list-item-content>Expirados</v-list-item-content>
          </v-list-item>
          <v-divider />
          <RemessaUploadDialog />
          <v-list-item to='/transfers'>
            <v-list-item-action>
              <v-icon>mdi-cash-register</v-icon>
            </v-list-item-action>
            <v-list-item-content>Transferências</v-list-item-content>
          </v-list-item>
        </template>
        <template v-else>
          <AppLoginDialog>
            <template v-slot:activator="{on}">
              <v-list-item v-on="on">
                <v-list-item-action>
                  <v-icon>mdi-login-variant</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Entrar</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </AppLoginDialog>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app fixed clipped-left clipped-right>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title>
        <v-icon large color="deep-purple darken-4">mdi-bank-transfer</v-icon>Boletos
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <template v-if="$store.getters['account/loggedin']">
          <AppAccountMenu />
        </template>
        <template v-else>
          <AppLoginDialog />
        </template>
      </v-toolbar-items>
    </v-app-bar>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import AppLoginDialog from './AppLoginDialog.vue'
import AppAccountMenu from './AppAccountMenu.vue'
import RemessaUploadDialog from './RemessaUploadDialog.vue'

export default {
  components: {
    AppLoginDialog,
    AppAccountMenu,
    RemessaUploadDialog
  },

  computed: {
    ...mapState(['account'])
  },
  data () {
    return {
      drawer: null
    }
  }
}
</script>
