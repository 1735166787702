import axios from '../../services/axios'

const state = {
  username: localStorage.getItem('username') || null,
  authToken: localStorage.getItem('token') || null
}

const getters = {
  loggedin: (state) => !!state.authToken
}

const mutations = {
  login (state, { username, loginResponse }) {
    state.username = username
    state.authToken = loginResponse.access
  },
  logout (state) {
    state.username = null
    state.authToken = null
  }
}

const actions = {
  async login ({ commit }, { username, password }) {
    const response = await axios.post(
      '/api/token/', {
        username,
        password
      })

    commit('login', {
      username,
      loginResponse: response.data
    })

    localStorage.setItem('username', username)
    localStorage.setItem('token', response.data.access)
  },
  async logout ({ commit }) {
    commit('logout')
    localStorage.removeItem('username')
    localStorage.removeItem('token')
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
