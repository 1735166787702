<template>
  <v-container fluid>
    <v-row>
      <v-col md6>
        <RequestedBoletosList />
      </v-col>
      <v-col md6>
        <BoletoInfoTab />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RequestedBoletosList from './RequestedBoletosList'
import BoletoInfoTab from './BoletoInfoTab'

export default {
  components: {
    RequestedBoletosList,
    BoletoInfoTab
  }
}
</script>
