import axios from '../../services/axios'

const state = {
  balance: null
}

const getters = {
}

const mutations = {
  setBalance (state, { balance }) {
    state.balance = parseFloat(balance)
  }
}

const actions = {
  async getBalance ({ commit }) {
    const response = await axios.get(
      '/api/boletos/balance')
    commit('setBalance', response.data)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
