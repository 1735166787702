<template>
  <v-app>
    <AppNav />
    <router-view :key="$route.path"/>
  </v-app>
</template>

<script>
import AppNav from './components/AppNav.vue'

export default {
  name: 'App',

  components: {
    AppNav
  }
}
</script>
