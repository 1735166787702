import Vue from 'vue'
import Vuex from 'vuex'

import account from './modules/account'
import requestedBoletos from './modules/requestedBoletos'
import banking from './modules/banking'
import remessa from './modules/remessa'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    account,
    requestedBoletos,
    banking,
    remessa
  }
})
