<template>
      <v-list-item three-line @click="fetchData(boleto.id)" >
        <v-list-item-avatar>
            <v-icon v-if="boleto.status === 'SOLICITADO'">mdi-clock-outline</v-icon>
            <v-icon v-else-if="boleto.status === 'REGISTRADO'" color="blue">mdi-content-save</v-icon>
            <v-icon v-else-if="boleto.status === 'RECEBIDO'">mdi-elevator-down</v-icon>
            <v-icon v-else-if="boleto.status === 'VENCIDO'" color="red">mdi-clock-alert</v-icon>
            <v-icon v-else-if="boleto.status === 'FALHA'" color="red">mdi-cancel</v-icon>
            <v-icon v-else-if="boleto.status === 'TRANSFERIDO'" color="green">mdi-currency-usd</v-icon>
            <v-icon v-else-if="boleto.status === 'LIQUIDADO'" color="green">mdi-currency-usd</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            {{ boleto.nomePagador }}
          </v-list-item-title>
          <v-list-item-title>
            Status: {{ boleto.status }}
          </v-list-item-title>
          <v-list-item-subtitle>
            Valor: R$ {{ (Number(boleto.vlNominalTitulo) / 100).toFixed(2) }}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            Data de emissão: {{ boleto.dtEmissaoTitulo }} |
            Data de vencimento: {{ boleto.dtVencimentoTitulo }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
</template>

<script>
export default {
  props: {
    boleto: Object
  },

  methods: {
    async fetchData (id) {
      await this.$store.dispatch('requestedBoletos/getRespostaBoleto', id)
    }
  }
}
</script>
