import axios from '../../services/axios'

const state = {
  selected: null,
  all: null
}

const getters = {

}

const mutations = {
  setRemessas (state, { data }) {
    state.all = data
  },

  setRemessa (state, { data }) {
    state.selected = data
  }
}

const actions = {
  async getRemessas ({ commit }, { year, month }) {
    const response = await axios.get(
      `/api/boletos/remessa?year=${year}&month=${month}`)
    commit('setRemessas', response)
  },

  async getRemessa ({ commit }, { id }) {
    const response = await axios.get(
      `/api/boletos/remessa/${id}/details`)
    commit('setRemessa', response)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
