<template>
  <v-card>
    <v-card-title class="justify-center">Informações Detalhadas</v-card-title>
    <v-tabs centered>
      <v-tab>Requisição</v-tab>
      <v-tab>Resposta</v-tab>
      <v-tab>Baixar Boleto </v-tab>

      <v-tab-item>
        <v-card-text>
          <v-simple-table dense>
            <template v-slot:default v-if="!!requestedBoletos.selectedRequest">
              <tbody>
                <tr>
                  <td>Nome do Pagador</td>
                  <td>{{ requestedBoletos.selectedRequest.nomePagador }}</td>
                </tr>
                <tr>
                  <td>Endereço do Pagador</td>
                  <td>{{ requestedBoletos.selectedRequest.logradouroPagador }}</td>
                </tr>
                <tr>
                  <td>Número do Endereço do Pagador</td>
                  <td>{{ requestedBoletos.selectedRequest.nuLogradouroPagador }}</td>
                </tr>
                <tr>
                  <td>Complemento do Endereço do Pagador</td>
                  <td>{{ requestedBoletos.selectedRequest.complementoLogradouroPagador }}</td>
                </tr>
                <tr>
                  <td>CEP do Pagador</td>
                  <td>{{ requestedBoletos.selectedRequest.cepPagador }}</td>
                </tr>
                <tr>
                  <td>Complemento CEP do Pagador</td>
                  <td>{{ requestedBoletos.selectedRequest.complementoCepPagador }}</td>
                </tr>
                <tr>
                  <td>Bairro do Pagador</td>
                  <td>{{ requestedBoletos.selectedRequest.bairroPagador }}</td>
                </tr>
                <tr>
                  <td>Município do Pagador</td>
                  <td>{{ requestedBoletos.selectedRequest.municipioPagador }}</td>
                </tr>
                <tr>
                  <td>UF do Pagador</td>
                  <td>{{ requestedBoletos.selectedRequest.ufPagador }}</td>
                </tr>
                <tr>
                  <td>Indicador CPF/CNPJ (1 - CPF | 2 - CNPJ)</td>
                  <td>{{ requestedBoletos.selectedRequest.cdIndCpfcnpjPagador }}</td>
                </tr>
                <tr>
                  <td>Endereço Eletrônico do Pagador</td>
                  <td>{{ requestedBoletos.selectedRequest.endEletronicoPagador }}</td>
                </tr>
                <tr>
                  <td>Data de Emissão do Título</td>
                  <td>{{ requestedBoletos.selectedRequest.dtEmissaoTitulo }}</td>
                </tr>
                <tr>
                  <td>Data de Vencimento do Título</td>
                  <td>{{ requestedBoletos.selectedRequest.dtVencimentoTitulo }}</td>
                </tr>
                <tr>
                  <td>Tipo de Vencimento – (Fixo “0”)</td>
                  <td>{{ requestedBoletos.selectedRequest.tpVencimento }}</td>
                </tr>
                <tr>
                  <td>Valor Nominal do Título</td>
                  <td>R$ {{ (Number(requestedBoletos.selectedRequest.vlNominalTitulo) / 100).toFixed(2) }}</td>
                </tr>
                <tr>
                  <td>Código da Espécie do Título</td>
                  <td>{{ requestedBoletos.selectedRequest.cdEspecieTitulo }}</td>
                </tr>
                <tr>
                  <td>Tipo de Protesto Automático ou Negativação</td>
                  <td>{{ requestedBoletos.selectedRequest.tpProtestoAutomaticoNegativacao }}</td>
                </tr>
                <tr>
                  <td>Prazo para Protesto Automático ou Negativação</td>
                  <td>{{ requestedBoletos.selectedRequest.prazoProtestoAutomaticoNegativacao }}</td>
                </tr>
                <tr>
                  <td>Controle Participante</td>
                  <td>{{ requestedBoletos.selectedRequest.controleParticipante }}</td>
                </tr>
                <tr>
                  <td>Indicador de Pagamento</td>
                  <td>{{ requestedBoletos.selectedRequest.cdPagamentoParcial }}</td>
                </tr>
                <tr>
                  <td>Quantidade de Pagamentos Parciais</td>
                  <td>{{ requestedBoletos.selectedRequest.qtdePagamentoParcial }}</td>
                </tr>
                <tr>
                  <td>Percentual de Juros</td>
                  <td>{{ requestedBoletos.selectedRequest.percentualJuros }}</td>
                </tr>
                <tr>
                  <td>Valor de Juros</td>
                  <td>{{ requestedBoletos.selectedRequest.vlJuros }}</td>
                </tr>
                <tr>
                  <td>Quantidade de dias para cálculo Juros</td>
                  <td>{{ requestedBoletos.selectedRequest.qtdeDiasJuros }}</td>
                </tr>
                <tr>
                  <td>Percentual de Multa</td>
                  <td>{{ requestedBoletos.selectedRequest.percentualMulta }}</td>
                </tr>
                <tr>
                  <td>Valor da Multa</td>
                  <td>{{ requestedBoletos.selectedRequest.vlMulta }}</td>
                </tr>
                <tr>
                  <td>Quantidade de dias para cálculo Multa</td>
                  <td>{{ requestedBoletos.selectedRequest.qtdeDiasMulta }}</td>
                </tr>
                <tr>
                  <td>Percentual do Desconto 1</td>
                  <td>{{ requestedBoletos.selectedRequest.percentualDesconto1 }}</td>
                </tr>
                <tr>
                  <td>Valor do Desconto 1</td>
                  <td>{{ requestedBoletos.selectedRequest.vlDesconto1 }}</td>
                </tr>
                <tr>
                  <td>Data Limite para Desconto 1</td>
                  <td>{{ requestedBoletos.selectedRequest.dataLimiteDesconto1 }}</td>
                </tr>
                <tr>
                  <td>Percentual do Desconto 2</td>
                  <td>{{ requestedBoletos.selectedRequest.percentualDesconto2 }}</td>
                </tr>
                <tr>
                  <td>Valor do Desconto 2</td>
                  <td>{{ requestedBoletos.selectedRequest.vlDesconto2 }}</td>
                </tr>
                <tr>
                  <td>Data Limite para Desconto 3</td>
                  <td>{{ requestedBoletos.selectedRequest.dataLimiteDesconto3 }}</td>
                </tr>
                <tr>
                  <td>Percentual do Desconto 3</td>
                  <td>{{ requestedBoletos.selectedRequest.percentualDesconto3 }}</td>
                </tr>
                <tr>
                  <td>Valor do Desconto 3</td>
                  <td>{{ requestedBoletos.selectedRequest.vlDesconto3 }}</td>
                </tr>
                <tr>
                  <td>Data Limite para Desconto 3</td>
                  <td>{{ requestedBoletos.selectedRequest.dataLimiteDesconto3 }}</td>
                </tr>
                <tr>
                  <td>Prazo para Bonificação</td>
                  <td>{{ requestedBoletos.selectedRequest.prazoBonificacao }}</td>
                </tr>
                <tr>
                  <td>Percentual de Bonificação</td>
                  <td>{{ requestedBoletos.selectedRequest.percentualBonificacao }}</td>
                </tr>
                <tr>
                  <td>Valor de Bonificação</td>
                  <td>{{ requestedBoletos.selectedRequest.vlBonificacao }}</td>
                </tr>
                <tr>
                  <td>Data Limite para Bonificação</td>
                  <td>{{ requestedBoletos.selectedRequest.dtLimiteBonificacao }}</td>
                </tr>
                <tr>
                  <td>Valor do Abatimento</td>
                  <td>{{ requestedBoletos.selectedRequest.vlAbatimento }}</td>
                </tr>
                <tr>
                  <td>Valor do IOF</td>
                  <td>{{ requestedBoletos.selectedRequest.vlIOF }}</td>
                </tr>
                <tr>
                  <td>Nome do Sacador Avalista</td>
                  <td>{{ requestedBoletos.selectedRequest.nomeSacadorAvalista }}</td>
                </tr>
                <tr>
                  <td>Endereço do Sacador Avalista</td>
                  <td>{{ requestedBoletos.selectedRequest.logradouroSacadorAvalista }}</td>
                </tr>
                <tr>
                  <td>Número do Endereço do Sacador Avalista</td>
                  <td>{{ requestedBoletos.selectedRequest.nuLogradouroSacadorAvalista }}</td>
                </tr>
                <tr>
                  <td>Complemento do Endereço do Sacador Avalista</td>
                  <td>{{ requestedBoletos.selectedRequest.complementoLogradouroSacadorAvalista }}</td>
                </tr>
                <tr>
                  <td>CEP do Sacador Avalista</td>
                  <td>{{ requestedBoletos.selectedRequest.cepSacadorAvalista }}</td>
                </tr>
                <tr>
                  <td>Complemento do CEP do Sacador Avalista</td>
                  <td>{{ requestedBoletos.selectedRequest.complementoCepSacadorAvalista }}</td>
                </tr>
                <tr>
                  <td>Bairro do Sacador Avalista</td>
                  <td>{{ requestedBoletos.selectedRequest.bairroSacadorAvalista }}</td>
                </tr>
                <tr>
                  <td>Município do Sacador Avalista</td>
                  <td>{{ requestedBoletos.selectedRequest.municipioSacadorAvalista }}</td>
                </tr>
                <tr>
                  <td>UF do Sacador Avalista</td>
                  <td>{{ requestedBoletos.selectedRequest.ufSacadorAvalista }}</td>
                </tr>
                <tr>
                  <td>Indicador CPF/CNPJ do Sacador Avalista (1 - CPF | 2 - CNPJ)</td>
                  <td>{{ requestedBoletos.selectedRequest.cdIndCpfcnpjSacadorAvalista }}</td>
                </tr>
                <tr>
                  <td>CPF/CNPJ do Sacador Avalista</td>
                  <td>{{ requestedBoletos.selectedRequest.nuCpfcnpjSacadorAvalista }}</td>
                </tr>
                <tr>
                  <td>Endereço Eletrônico do Sacador Avalista</td>
                  <td>{{ requestedBoletos.selectedRequest.endEletronicoSacadorAvalista }}</td>
                </tr>
                <tr>
                  <td>Número CPF/CNPJ</td>
                  <td>{{ requestedBoletos.selectedRequest.nuCPFCNPJ }}</td>
                </tr>
                <tr>
                  <td>Filial CPF/CNPJ</td>
                  <td>{{ requestedBoletos.selectedRequest.filialCPFCNPJ }}</td>
                </tr>
                <tr>
                  <td>Digito Verificador CPF/CNPJ</td>
                  <td>{{ requestedBoletos.selectedRequest.ctrlCPFCNPJ }}</td>
                </tr>
                <tr>
                  <td>Tipo de Acesso (Fixo “2” – Negociação)</td>
                  <td>{{ requestedBoletos.selectedRequest.cdTipoAcesso }}</td>
                </tr>
                <tr>
                  <td>Club Banco – 237 (Bradesco) (Fixo “2269651”)</td>
                  <td>{{ requestedBoletos.selectedRequest.clubBanco }}</td>
                </tr>
                <tr>
                  <td>Tipo de Contrato – (Fixo “48”)</td>
                  <td>{{ requestedBoletos.selectedRequest.cdTipoContrato }}</td>
                </tr>
                <tr>
                  <td>Número de Sequência do Contrato</td>
                  <td>{{ requestedBoletos.selectedRequest.nuSequenciaContrato }}</td>
                </tr>
                <tr>
                  <td>ID Produto (código da carteira/modalidade de cobrança)</td>
                  <td>{{ requestedBoletos.selectedRequest.idProduto }}</td>
                </tr>
                <tr>
                  <td>Número da Negociação</td>
                  <td>{{ requestedBoletos.selectedRequest.nuNegociacao }}</td>
                </tr>
                <tr>
                  <td>Código do Banco – (Fixo “237”)</td>
                  <td>{{ requestedBoletos.selectedRequest.cdBanco }}</td>
                </tr>
                <tr>
                  <td>Número de Sequência do Contrato</td>
                  <td>{{ requestedBoletos.selectedRequest.eNuSequenciaContrato }}</td>
                </tr>
                <tr>
                  <td>Tipo de Registro – (Fixo “1” à vencer/vencido)</td>
                  <td>{{ requestedBoletos.selectedRequest.tpRegistro }}</td>
                </tr>
                <tr>
                  <td>Código do Produto</td>
                  <td>{{ requestedBoletos.selectedRequest.cdProduto }}</td>
                </tr>
                <tr>
                  <td>Número do Título</td>
                  <td>{{ requestedBoletos.selectedRequest.nuTitulo }}</td>
                </tr>
                <tr>
                  <td>Número do Cliente</td>
                  <td>{{ requestedBoletos.selectedRequest.nuCliente }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-tab-item>
      <v-tab-item>
      <v-card-text>
          <v-simple-table dense>
            <template v-slot:default v-if="!!requestedBoletos.selectedResponse">
              <tbody>
                <tr>
                  <td>Código de Erro</td>
                  <td>{{ requestedBoletos.selectedResponse.cdErro }}</td>
                </tr>
                <tr>
                  <td>Mensagem de Erro</td>
                  <td>{{ requestedBoletos.selectedResponse.msgErro }}</td>
                </tr>
                <tr>
                  <td>ID do Produto</td>
                  <td>{{ requestedBoletos.selectedResponse.idProduto }}</td>
                </tr>
                <tr>
                  <td>Negociação</td>
                  <td>{{ requestedBoletos.selectedResponse.negociacao }}</td>
                </tr>
                <tr>
                  <td>Club Banco</td>
                  <td>{{ requestedBoletos.selectedResponse.clubBanco }}</td>
                </tr>
                <tr>
                  <td>Tipo de Contrato</td>
                  <td>{{ requestedBoletos.selectedResponse.tpContrato }}</td>
                </tr>
                <tr>
                  <td>Número de Sequencia do Contrato</td>
                  <td>{{ requestedBoletos.selectedResponse.nuSequenciaContrato }}</td>
                </tr>
                <tr>
                  <td>Código do Produto</td>
                  <td>{{ requestedBoletos.selectedResponse.cdProduto }}</td>
                </tr>
                <tr>
                  <td>Número do Título Gerado</td>
                  <td>{{ requestedBoletos.selectedResponse.nuTituloGerado }}</td>
                </tr>
                <tr>
                  <td>Agência de Crédito do Beneficiário</td>
                  <td>{{ requestedBoletos.selectedResponse.agenciaCreditoBeneficiario }}</td>
                </tr>
                <tr>
                  <td>Conta de Crédito do Beneficiário</td>
                  <td>{{ requestedBoletos.selectedResponse.contaCreditoBeneficiario }}</td>
                </tr>
                <tr>
                  <td>Dígito da Conta de Crédito</td>
                  <td>{{ requestedBoletos.selectedResponse.digCreditoBeneficiario }}</td>
                </tr>
                <tr>
                  <td>Código da CIP do Título</td>
                  <td>{{ requestedBoletos.selectedResponse.cdCipTitulo }}</td>
                </tr>
                <tr>
                  <td>Status do Título</td>
                  <td>{{ requestedBoletos.selectedResponse.statusTitulo }}</td>
                </tr>
                <tr>
                  <td>Descrição do Status do Título</td>
                  <td>{{ requestedBoletos.selectedResponse.descStatusTitulo }}</td>
                </tr>
                <tr>
                  <td>Nome do Beneficiário</td>
                  <td>{{ requestedBoletos.selectedResponse.nomeBeneficiario }}</td>
                </tr>
                <tr>
                  <td>Endereço do Beneficiário</td>
                  <td>{{ requestedBoletos.selectedResponse.logradouroBeneficiario }}</td>
                </tr>
                <tr>
                  <td>Número do Endereço do Beneficiário</td>
                  <td>{{ requestedBoletos.selectedResponse.nuLogradouroBeneficiario }}</td>
                </tr>
                <tr>
                  <td>Complemento do Endereço do Beneficiário</td>
                  <td>{{ requestedBoletos.selectedResponse.complementoLogradouroBeneficiario }}</td>
                </tr>
                <tr>
                  <td>Bairro do Endereço do Beneficiário</td>
                  <td>{{ requestedBoletos.selectedResponse.bairroBeneficiario }}</td>
                </tr>
                <tr>
                  <td>CEP do Endereço do Beneficiário</td>
                  <td>{{ requestedBoletos.selectedResponse.cepBeneficiario }}</td>
                </tr>
                <tr>
                  <td>Complemento do CEP do Endereço do Beneficiário</td>
                  <td>{{ requestedBoletos.selectedResponse.cepComplementoBeneficiario }}</td>
                </tr>
                <tr>
                  <td>Município do Endereço do Beneficiário</td>
                  <td>{{ requestedBoletos.selectedResponse.municipioBeneficiario }}</td>
                </tr>
                <tr>
                  <td>UF do Endereço do Beneficiário</td>
                  <td>{{ requestedBoletos.selectedResponse.ufBeneficiario }}</td>
                </tr>
                <tr>
                  <td>Razão da Conta de Crédito do Beneficiário</td>
                  <td>{{ requestedBoletos.selectedResponse.razaoContaBeneficiario }}</td>
                </tr>
                <tr>
                  <td>Nome do Pagador do Título</td>
                  <td>{{ requestedBoletos.selectedResponse.nomePagador }}</td>
                </tr>
                <tr>
                  <td>CPF/CNPJ do Pagador</td>
                  <td>{{ requestedBoletos.selectedResponse.cpfcnpjPagador }}</td>
                </tr>
                <tr>
                  <td>Endereço do Pagador</td>
                  <td>{{ requestedBoletos.selectedResponse.enderecoPagador }}</td>
                </tr>
                <tr>
                  <td>Bairro do Endereço do Pagador</td>
                  <td>{{ requestedBoletos.selectedResponse.bairroPagador }}</td>
                </tr>
                <tr>
                  <td>Município do Endereço do Pagador</td>
                  <td>{{ requestedBoletos.selectedResponse.municipioPagador }}</td>
                </tr>
                <tr>
                  <td>UF do Endereço do Pagador</td>
                  <td>{{ requestedBoletos.selectedResponse.ufPagador }}</td>
                </tr>
                <tr>
                  <td>CEP do Endereço do Pagador</td>
                  <td>{{ requestedBoletos.selectedResponse.cepPagador }}</td>
                </tr>
                <tr>
                  <td>Complemento do CEP do Endereço do Pagador</td>
                  <td>{{ requestedBoletos.selectedResponse.cepComplementoPagador }}</td>
                </tr>
                <tr>
                  <td>Endereço Eletrônico do Pagador</td>
                  <td>{{ requestedBoletos.selectedResponse.endEletronicoPagador }}</td>
                </tr>
                <tr>
                  <td>Nome do Sacador Avalista do Título</td>
                  <td>{{ requestedBoletos.selectedResponse.nomeSacadorAvalista }}</td>
                </tr>
                <tr>
                  <td>CPF/CNPJ do Sacador Avalista do Título</td>
                  <td>{{ requestedBoletos.selectedResponse.cpfcnpjSacadorAvalista }}</td>
                </tr>
                <tr>
                  <td>Endereço do Sacador Avalista</td>
                  <td>{{ requestedBoletos.selectedResponse.enderecoSacadorAvalista }}</td>
                </tr>
                <tr>
                  <td>Município do Sacador Avalista</td>
                  <td>{{ requestedBoletos.selectedResponse.municipioSacadorAvalista }}</td>
                </tr>
                <tr>
                  <td>UF do Sacador Avalista</td>
                  <td>{{ requestedBoletos.selectedResponse.ufSacadorAvalista }}</td>
                </tr>
                <tr>
                  <td>CEP do Sacador Avalista</td>
                  <td>{{ requestedBoletos.selectedResponse.cepSacadorAvalista }}</td>
                </tr>
                <tr>
                  <td>Complemento do CEP do Sacador Avalista</td>
                  <td>{{ requestedBoletos.selectedResponse.cepComplementoSacadorAvalista }}</td>
                </tr>
                <tr>
                  <td>Seu Número do Título</td>
                  <td>{{ requestedBoletos.selectedResponse.numeroTitulo }}</td>
                </tr>
                <tr>
                  <td>Data de Registro</td>
                  <td>{{ requestedBoletos.selectedResponse.dtRegistro }}</td>
                </tr>
                <tr>
                  <td>Espécie do Documento do Título</td>
                  <td>{{ requestedBoletos.selectedResponse.especieDocumentoTitulo }}</td>
                </tr>
                <tr>
                  <td>Descrição da Espécie</td>
                  <td>{{ requestedBoletos.selectedResponse.descEspecie }}</td>
                </tr>
                <tr>
                  <td>Valor IOF</td>
                  <td>{{ requestedBoletos.selectedResponse.vlIOF }}</td>
                </tr>
                <tr>
                  <td>Data de Emissão</td>
                  <td>{{ requestedBoletos.selectedResponse.dtEmissao }}</td>
                </tr>
                <tr>
                  <td>Data de Vencimento</td>
                  <td>{{ requestedBoletos.selectedResponse.dtVencimento }}</td>
                </tr>
                <tr>
                  <td>Valor do Título</td>
                  <td>{{ requestedBoletos.selectedResponse.vlTitulo }}</td>
                </tr>
                <tr>
                  <td>Valor do Abatimento</td>
                  <td>{{ requestedBoletos.selectedResponse.vlAbatimento }}</td>
                </tr>
                <tr>
                  <td>Data Instrução de Protesto / Negativação</td>
                  <td>{{ requestedBoletos.selectedResponse.dtInstrucaoProtestoNegativação }}</td>
                </tr>
                <tr>
                  <td>Dias Instrução de Protesto / Negativação</td>
                  <td>{{ requestedBoletos.selectedResponse.diasInstrucaoProtestoNegativação }}</td>
                </tr>
                <tr>
                  <td>Data da Multa</td>
                  <td>{{ requestedBoletos.selectedResponse.dtMulta }}</td>
                </tr>
                <tr>
                  <td>Valor da Multa</td>
                  <td>{{ requestedBoletos.selectedResponse.vlMulta }}</td>
                </tr>
                <tr>
                  <td>Quant. de Casas Decimais da Multa</td>
                  <td>{{ requestedBoletos.selectedResponse.qtdeCasasDecimaisMulta }}</td>
                </tr>
                <tr>
                  <td>Código do Valor da Multa (1-Valor, 2-Percentual)</td>
                  <td>{{ requestedBoletos.selectedResponse.cdValorMulta }}</td>
                </tr>
                <tr>
                  <td>Descrição do Código da Multa</td>
                  <td>{{ requestedBoletos.selectedResponse.descCdMulta }}</td>
                </tr>
                <tr>
                  <td>Data de Juros</td>
                  <td>{{ requestedBoletos.selectedResponse.dtJuros }}</td>
                </tr>
                <tr>
                  <td>Valor de Juros ao Dia</td>
                  <td>{{ requestedBoletos.selectedResponse.vlJurosAoDia }}</td>
                </tr>
                <tr>
                  <td>Data do Desconto 1</td>
                  <td>{{ requestedBoletos.selectedResponse.dtDesconto1Bonificacao }}</td>
                </tr>
                <tr>
                  <td>Valor do Desconto 1</td>
                  <td>{{ requestedBoletos.selectedResponse.vlDesconto1Bonificacao }}</td>
                </tr>
                <tr>
                  <td>Quant. de Casas Decimais do Desconto 1</td>
                  <td>{{ requestedBoletos.selectedResponse.qtdeCasasDecimaisDesconto1Bonificacao }}</td>
                </tr>
                <tr>
                  <td>Código do Valor do Desconto 1</td>
                  <td>{{ requestedBoletos.selectedResponse.cdValorDesconto1Bonificacao }}</td>
                </tr>
                <tr>
                  <td>Descrição do Código do Valor do Desconto 1</td>
                  <td>{{ requestedBoletos.selectedResponse.descCdDesconto1Bonificacao }}</td>
                </tr>
                <tr>
                  <td>Data do Desconto 2</td>
                  <td>{{ requestedBoletos.selectedResponse.dtDesconto2 }}</td>
                </tr>
                <tr>
                  <td>Valor do Desconto 2</td>
                  <td>{{ requestedBoletos.selectedResponse.vlDesconto2 }}</td>
                </tr>
                <tr>
                  <td>Quant. de Casas Decimais do Desconto 2</td>
                  <td>{{ requestedBoletos.selectedResponse.qtdeCasasDecimaisDesconto2 }}</td>
                </tr>
                <tr>
                  <td>Código do Valor do Desconto 2</td>
                  <td>{{ requestedBoletos.selectedResponse.cdValorDesconto2 }}</td>
                </tr>
                <tr>
                  <td>Descrição do Código do Valor do Desconto 2</td>
                  <td>{{ requestedBoletos.selectedResponse.descCdDesconto2 }}</td>
                </tr>
                <tr>
                  <td>Data do Desconto 3</td>
                  <td>{{ requestedBoletos.selectedResponse.dtDesconto3 }}</td>
                </tr>
                <tr>
                  <td>Valor do Desconto 3</td>
                  <td>{{ requestedBoletos.selectedResponse.vlDesconto3 }}</td>
                </tr>
                <tr>
                  <td>Quant. de Casas Decimais do Desconto 3</td>
                  <td>{{ requestedBoletos.selectedResponse.qtdeCasasDecimaisDesconto3 }}</td>
                </tr>
                <tr>
                  <td>Código do Valor do Desconto 3</td>
                  <td>{{ requestedBoletos.selectedResponse.cdValorDesconto3 }}</td>
                </tr>
                <tr>
                  <td>Descrição do Código do Valor do Desconto 3</td>
                  <td>{{ requestedBoletos.selectedResponse.descCdDesconto3 }}</td>
                </tr>
                <tr>
                  <td>Dias de Dispensa de Multa</td>
                  <td>{{ requestedBoletos.selectedResponse.diasDispensaMulta }}</td>
                </tr>
                <tr>
                  <td>Dias de Dispensa de Juros</td>
                  <td>{{ requestedBoletos.selectedResponse.diasDispensaJuros }}</td>
                </tr>
                <tr>
                  <td>Linha Digitável</td>
                  <td>{{ requestedBoletos.selectedResponse.linhaDigitavel }}</td>
                </tr>
                <tr>
                  <td>Código do Acessório Escritural da Empresa</td>
                  <td>{{ requestedBoletos.selectedResponse.cdAcessorioEscrituralEmpresa }}</td>
                </tr>
                <tr>
                  <td>Tipo de Vencimento</td>
                  <td>{{ requestedBoletos.selectedResponse.tpVencimento }}</td>
                </tr>
                <tr>
                  <td>Indicador de Instrução de Processo</td>
                  <td>{{ requestedBoletos.selectedResponse.indInstrucaoProtesto }}</td>
                </tr>
                <tr>
                  <td>Tipo do Abatimento do Título</td>
                  <td>{{ requestedBoletos.selectedResponse.tipoAbatimentoTitulo }}</td>
                </tr>
                <tr>
                  <td>Código do Valor do Juros (1-Valor ao dia, 2-Percentual ao mês)</td>
                  <td>{{ requestedBoletos.selectedResponse.cdValorJuros }}</td>
                </tr>
                <tr>
                  <td>Tipo de Desconto 1</td>
                  <td>{{ requestedBoletos.selectedResponse.tpDesconto1 }}</td>
                </tr>
                <tr>
                  <td>Tipo de Desconto 2</td>
                  <td>{{ requestedBoletos.selectedResponse.tpDesconto2 }}</td>
                </tr>
                <tr>
                  <td>Tipo de Desconto 3</td>
                  <td>{{ requestedBoletos.selectedResponse.tpDesconto3 }}</td>
                </tr>
                <tr>
                  <td>Número do Controle do Participante</td>
                  <td>{{ requestedBoletos.selectedResponse.nuControleParticipante }}</td>
                </tr>
                <tr>
                  <td>Dias de Juros</td>
                  <td>{{ requestedBoletos.selectedResponse.diasJuros }}</td>
                </tr>
                <tr>
                  <td>Código de Juros (1-Valor ao dia, 2-Percentual ao mês)</td>
                  <td>{{ requestedBoletos.selectedResponse.cdJuros }}</td>
                </tr>
                <tr>
                  <td>Valor Juros</td>
                  <td>{{ requestedBoletos.selectedResponse.vlJuros }}</td>
                </tr>
                <tr>
                  <td>CPF/CNPJ Beneficiário</td>
                  <td>{{ requestedBoletos.selectedResponse.cpfcnpjBeneficiário }}</td>
                </tr>
                <tr>
                  <td>Valor do Título Emitido no Boleto</td>
                  <td>{{ requestedBoletos.selectedResponse.vlTituloEmitidoBoleto }}</td>
                </tr>
                <tr>
                  <td>Data de Vencimento do Boleto</td>
                  <td>{{ requestedBoletos.selectedResponse.dtVencimentoBoleto }}</td>
                </tr>
                <tr>
                  <td>Indica se o título pertence a base de títulos de beneficiários cadastrados no DDA</td>
                  <td>{{ requestedBoletos.selectedResponse.indTituloPertenceBaseTitulos }}</td>
                </tr>
                <tr>
                  <td>Data Limite Para Pagamento do Boleto</td>
                  <td>{{ requestedBoletos.selectedResponse.dtLimitePagamentoBoleto }}</td>
                </tr>
                <tr>
                  <td>Código da Identificação de Título DDA na CIP</td>
                  <td>{{ requestedBoletos.selectedResponse.cdIdentificacaoTituloDDACIP }}</td>
                </tr>
                <tr>
                  <td>Indicador de Pagamento Parcial</td>
                  <td>{{ requestedBoletos.selectedResponse.indPagamentoParcial }}</td>
                </tr>
                <tr>
                  <td>Quantidade Pagamentos Parciais</td>
                  <td>{{ requestedBoletos.selectedResponse.qtdePagamentoParciais }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-tab-item>
      <v-tab-item>
        <v-card-text v-if="!requestedBoletos.selectedResponse || requestedBoletos.selectedResponse.cdErro !== '0'">
          <v-alert type="error">
            Boleto indisponível, verificar na aba "Resposta" ao lado, o erro recebido.
          </v-alert>
        </v-card-text>
        <v-card-text v-else class="d-flex justify-space-around">
          <div>
            <v-btn @click="downloadBoleto(requestedBoletos.selectedRequest.id)">
              <v-icon left>mdi-cloud-download</v-icon>
              Baixar
            </v-btn>
          </div>
          <div>
            <v-btn @click="imprimirBoleto(requestedBoletos.selectedRequest.id)">
              <v-icon left>mdi-cloud-print</v-icon>
              Imprimir
            </v-btn>
          </div>
        </v-card-text>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import axios from '../services/axios'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['requestedBoletos'])
  },

  methods: {
    async downloadBoleto (id) {
      const response = await axios.get(`/api/boletos/request/${id}/download/`)

      const blob = new Blob([response.data], { type: 'application/pdf' })
      const url = window.URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.href = url
      link.download = `Boleto ${this.requestedBoletos.selectedRequest.nomePagador}.pdf`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },

    async imprimirBoleto (id) {
      const response = await axios.get(`/api/boletos/request/${id}/download/`)

      const blob = new Blob([response.data], { type: 'application/pdf' })
      const url = window.URL.createObjectURL(blob)

      window.open(url, '_blank')
    }
  }
}
</script>
