<template>
  <div>
    <v-main>
      <HomePage v-if="$store.getters['account/loggedin']" />
      <v-container v-else fluid grid-list md>
        <h1>Por favor, faça login para usar o sistema.</h1>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import HomePage from '../components/HomePage.vue'

export default {
  name: 'Home',

  components: {
    HomePage
  }
}
</script>
